.landing{
  height: 100vh;
  width: 100vw;
  background: no-repeat center center / cover;
  position: relative;
  padding: 5vh 5vw 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  @media screen and (min-width: 551px){
    padding: 5vh 5vw 10vh;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-primary, 0.5);
  }
  &__logo{
    margin: 0 auto;
    width: 200px;
    height: auto;
    max-height: 90px;
    position: relative;
    z-index: 2;
    @media screen and (min-width: 351px){
      width: 270px;
    }
    @media screen and (min-width: 551px){
      width: 330px;
    }
  }
  &__info{
    position: relative;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    h1{
      width: 100%;
      font-size: 2rem;
      line-height: 1;
      color: $color-white;
      margin-bottom: 15px;
      font-weight: 700;
      @media screen and (min-width: 351px){
        font-size: 2.75rem;
      }
      @media screen and (min-width: 601px){
        font-size: 3.75rem;
      }
    }
  }
  .c-btn-img {
      margin: 0 20px 20px 0;
  }
}

.c-btn-img {
  font-size: 1rem;
  font-weight: 400;

  display: table;

  padding: 10px 40px;

  cursor: pointer;
  transition: all .25s ease;
  text-align: center;
  text-decoration: none;
  background-color: $color-primary;

  color: #fff;
  border: none;
  border-radius: 10px;
  outline: none;

  appearance: none;
  position: relative;
  box-shadow: 0 3px 3px rgba(#000000, 0.15);
  @media screen and (min-width: 551px){
    padding: 15px 60px;

  }

  &:before,
  &:after{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: no-repeat center center / contain;
  }

  &:before{
    left: 10px;
    width: 26px;
    height: 26px;
    @media screen and (min-width: 551px){
      left: 20px;
    }
  }
  &:after{
    right: 10px;
    top: 50%;
    width: 21px;
    height: 21px;
    background-image: url("../img/ico-arrow.svg");
    @media screen and (min-width: 551px){
      right: 20px;
    }
  }

  &:hover {
    background-color: lighten($color-primary, 10%);
  }
  &--email{
    @extend .c-btn-img;
    &:before{background-image: url("../img/ico-mail.svg");}
  }
  &--phone{
    @extend .c-btn-img;
    &:before{background-image: url("../img/ico-phone.svg");}
  }
}
