html {
  font-family: $font-primary;
  font-size: 16px;
}

body {
  overflow-x: hidden;

  min-width: 320px;
}

a {
  transition: all .25s ease;
  text-decoration: none;

  color: inherit;
}

